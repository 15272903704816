//== [ 共通読み込み ]=============
//変数・mixin
@use 'variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================


/* ---------------------------------------------------------------
Mixins
--------------------------------------------------------------- */
//デモグラメニュー：カレント表示用（$elem:対象クラス名）
@mixin navDemographicCurrent($elem){
  #{$elem} &{
    a{
      position: relative;
      color: #fff;
      z-index: 1;
      &:before {
        display: block;
        content: "";
        position: absolute;
        inset: 0;
        top: 50%;
        left: -12px;
        right: -12px;
        margin-top: -13px;
        height: 26px;
        background-color: #000;
        border-radius: 100vmax;
        z-index: -1;
      }
    }
  }
}

/* ---------------------------------------------------------------
header
--------------------------------------------------------------- */

.header {
    background-color: rgba(255,255,255,1);

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    // [ logo ]
    &__logo {
        font-size: 0;
    }

    // [ Navigation ]
    &__nav {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        &Search_ {
            margin-right: 20px;
            width: 18px;
            height: 18px;
        }

        // [ Function Menu ]
        &Function {
            display: flex;
            align-items: center;

            &Item {
                &--favorite {
                    width: 18px;
                    height: 18px;
                    margin-right: 20px;
                }
                &--store {
                    margin-right: 20px;
                }
                &--cart {
                    width: 21px;
                    height: 19px;
                    margin-right: 27px
                }
                &--menu {
                    width: 24px;
                    height: 19px;
                    line-height: 1;
                }
                &Btn {
                    position: relative;
                    display: block;
                    &Icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 16px;
                        height: 16px;
                        font-size: 1rem;
                        @include font-en;
                        line-height: 1;
                        background: $zoffBlue;
                        color: #fff;
                        border-radius: 50%;
                    }
                }
            }
        }
        // [ Store Seach Menu ]
        &StoreSheach {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.1rem;
            background: $zoffBlue;
            color: #fff;
            border-radius: 30px;
        }
    }
}

//----------- Layout -----------

.l- {
    &header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 11;

        &__inner {
            width: 100%;
            padding: 16px;
        }

        // [ logo ]
        &__logo {
            img {
                width: auto;
                height: 37px;
            }
        }

        // [ Navigation ]
        &__nav {
            height: 50px;

            // [ Function Menu ]
            &Function {
                &Item {
                    &Btn {
                        &Icon {
                            position: absolute;
                            top: -4px;
                            right: -0.8em;
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
            &StoreSheach {
                width: 66px;
                height: 26px;
            }
        }
    }
}

//=====[ Demographic Menu ]=====
.topHeaderMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    padding-left: calcVw(32);
    padding-bottom: 20px;
    padding-right: calcVw(32);
    font-size: 1.3rem;
    @include font-en;
    a {
        color: #000;
    }
    .headerMenu__item{
      
    }
}

.headerMenu {
    &__item {
        &--women{
            @include navDemographicCurrent('.women');//カレント表示用（対象クラスを指定）
        }
        &--men{
            @include navDemographicCurrent('.men');//カレント表示用（対象クラスを指定）
        }
        &--kids{
            @include navDemographicCurrent('.kids');//カレント表示用（対象クラスを指定）
        }
        &--sunglasses{
            @include navDemographicCurrent('.sunglasses');//カレント表示用（対象クラスを指定）
        }
        &--outlet{
            a {
                color: $colorCaution;
            }
            @include navDemographicCurrent('.outlet');//カレント表示用（対象クラスを指定）
        }
    }
}


@media screen and (min-width:667px) {
    .topHeaderMenu {
        max-width: 45rem;
    }
}