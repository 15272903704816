//== [ 共通読み込み ]=============
//変数・mixin
@use 'variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

body {
    font-family: $font-ja;
    line-height: 1.5;
    text-align: left;
  }

h1,h2 {
    font-size: 4rem;
    line-height: 1;
}

h3 {
    font-size: 3.3rem;
}

h4 {
    font-size: 1.8rem;
}

h5 {
    font-size: 1.8rem;
}

input {
    &::placeholder {
        color: #a7d1ef;
    }
}

input[type=image] {
    -webkit-appearance: none;
}


input, button, textarea, select {
    appearance: none;
	outline: none;
	&:focus {
		outline: none;
	}
}

input,select,textarea {
    border:1px solid $colorLightGray;
    font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
	vertical-align:middle;
	margin:0;
	border-radius: 0;
}

input[type=text],
input[type=search],
input[type=number],
input[type=tel],
input[type=email],
input[type=password],
textarea,
select {
	border:1px solid $colorLightGray;
	min-height:20px;
	max-width:100%;
}

input[type=text],
input[type=search],
input[type=number],
input[type=tel],
input[type=email],
input[type=image],
input[type=password]
{
	&:focus {
		border:none;
	}
}

input[type="text"],
input[type="tel"],
input[type="number"],
input[type="email"],
input[type="password"] {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: 100%;
    height: auto;
    padding: 15px 7px;
    border: 1px solid $zoffBlue;
    border-radius: 0;
    font-size: 1.6rem;
    line-height: 1.3;
}

hr {
	border:0;
    height:0;
}

small {
	font-size: 1.2rem;
}

/* ---------------------------------------------------------------
container
--------------------------------------------------------------- */
.container_ {
    position: relative;
    overflow: hidden;
    &__inner_ {
        
    }
}

/* ---------------------------------------------------------------
error
--------------------------------------------------------------- */
.error_ {
    padding-top: 10px;
    border:none;
    font-weight: bold;
    color: $colorCaution;
    background: #fff;
}

/* ---------------------------------------------------------------
page title
--------------------------------------------------------------- */
.styleGuide {
    // [pageTitle]
    &__pageTitle {
        font-size: 2rem;
        font-weight: normal;
        text-align: center;
        line-height: 1.4;
        color: $zoffBlue;
    }
}

.ly {
    &__styleGuide__pageTitle {
        margin: 0 0 30px;
        padding: 10px 0 20px;
    }
}

.pageTitle_ {
    margin: 0 0 30px;
    padding: 10px 0 20px;
    font-size: 2rem;
    font-weight: normal;
    text-align: center;
    line-height: 1.4;
    color: $zoffBlue;
}

.h3__title_ {
    margin-bottom: 15px;
    color: $zoffBlue;
    font-size: 1.6rem;
    font-weight: normal;
}

.h4__title_ {
    margin-bottom: 10px;
    color: $zoffBlue;
    font-size: 1.6rem;
    font-weight: normal;
    text-decoration: underline;
}

/* ---------------------------------------------------------------
button
--------------------------------------------------------------- */

.styleGuide {
    // [ button]
    &__button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        text-align: center;
        min-height: 42px;
        padding: 0 0.8em;
        // [ basecolor]
        &--base {
            background: $zoffBlue;
            color: #fff;
        }
        // [ whitecolor]
        &--frame {
            background: #fff;
            border: solid 1px $zoffBlue;
            box-sizing: border-box;
            color: $zoffBlue;
        }
    }
}

.input__redBtn_ {
    display: block;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    height: 84px;
    font-size: 1.6rem;
    text-align: center;
    line-height: 140%;
    color: #fff;
    border: 1px solid $colorAttention;
    background: $colorAttention;
    > span {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 20px;
        font-size: 1.8rem;
        text-align: left;
        img {
            width: 24px;
        }
    }
    small {
        display: block;
        font-size: 1.276rem;
        font-weight: bold;
    }
}

.btn__actFocusOff_ {
    &.on, .btn__actFocusOn_ {
        border: 1px solid $colorAttention !important;
        background: $colorAttention !important;
        color: #fff !important;
        margin-top: 5px;
        pointer-events: auto;
    }
}

#cart_next {
    font-size: 1.4rem;
    padding: 13px 5px;
    height: 60px;
    line-height: 1.3;
    width: 100%;
    max-width: 296px;
}

.termsCheck_disabled {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    text-align: center;
    line-height: 1.3;
    height: 52px;
    width: 100%;
    padding: 2px 0 0 0;
    margin-top: 20px;
    color: #fff;
    background: #b7b7b7;
}

.ly__styleGuide__button--colum {
    min-width: 31%;
    font-size: 1.4rem;
    &:first-of-type {
        margin-right: 1%;
    }
    &:last-of-type {
        margin-left: 1%;
    }
}

.btn__act_ {
    background: $zoffBlue;
    color: #fff;
    a {
        background: $zoffBlue;
        color: #fff;
        border: 1px solid $zoffBlue;
    }
}

.form__btn__wide_ {
    line-height: 1.4;
}

.btn__style_ {
    border: 1px solid $zoffBlue;
    width: 100%;
    font-size: 1.6rem;
    text-align: center;
    padding: 15px 5px;
    margin: 0;
    position: relative;
    border-radius: 0;
}

.main_action_button_ {
    text-shadow: none;
    background: $zoffBlue;
    border: none;
    border-bottom: none;
    color: #fff;
    font-size: 1.6rem;
    text-decoration: none;
    border-radius: 0;
    padding: 15px 0;
    width: 100%;
    margin: 5px 0;
}

.sub_action_button_ {
    border: 1px solid $zoffBlue;
    background: #fff;
    color: $zoffBlue;
    width: 100%;
    font-size: 1.6rem;
    text-align: center;
    padding: 15px 5px;
    position: relative;
    text-decoration: none;
    border-radius: 0;
    margin: 5px 0;
}

.btn__wide_ {
    a {
        border: 1px solid $zoffBlue;
        padding: 17px 7px 0;
        width: 100%;
        box-sizing: border-box;
        line-height: 1.3;
        color: #fff;
        text-align: center;
        display: block;
        font-size: 1.4rem;
        position: relative;
        height: 52px;
    }
}

.submit_ {
    text-align: center;
}

/* ---------------------------------------------------------------
hr
--------------------------------------------------------------- */
.hrBlock_ {
    position: relative;
    z-index: 1;
    padding-bottom: 25px;
    p {
        margin-bottom: 10px;
    }
    + .hrBlock_ {
        padding-top: 20px;
        border-top: 7px solid #f1f1f1;
        &::before {
            background: $colorDelimiterGray;
            content: "";
            position: absolute;
            top: -1px;
            width: 100%;
            height: 1px;
            z-index: 2;
        }
        &::after {
            background: $colorDelimiterGray;
            content: "";
            position: absolute;
            top: -7px;
            width: 100%;
            height: 1px;
            z-index: 2;
        }
    }
    .cartstatus__box_ {
        padding-top: 20px;
        border-top: 7px solid #f1f1f1;
    }
}

/* ---------------------------------------------------------------
tax
--------------------------------------------------------------- */
.itemPrice_tax_ {
    font-size: 1rem;
    margin-left: .1em;
    vertical-align: middle;
}

/* ---------------------------------------------------------------
label
--------------------------------------------------------------- */
.must_,.nomust_ {
    margin-left: 5px;
    vertical-align: top;
    max-width: 33px;
    margin-top: 2px;
}

.checkbox__label_ {
    position: relative;
    line-height: 1;
    padding-left: 25px;
    &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: #f1f1f1;
        border: 2px solid #757575;
        width: 15px;
        height: 15px;
    }
    input {
        display: none;
        &:checked {
            + .checkbox__parts_ {
                &::after {
                    content: "";
                    width: 14px;
                    height: 8px;
                    display: block;
                    border-top: $zoffBlue solid 2px;
                    border-right: $zoffBlue solid 2px;
                    position: absolute;
                    top: -2px;
                    left: -1px;
                    transform: rotate(135deg) translateY(-50%);
                }
            }
        }
    }
}

.obiTxt_ {
    display: inline-block;
    background: #bfbfbf;
    color: #fff;
    line-height: 1;
    padding: 3px 5px;
    font-size: 1.4rem;
    vertical-align: text-top;
    margin-left: 8px;
    min-width: 65px;
    text-align: center;
    &:first-child {
        margin-bottom: 0.5rem;
    }
    &.obi__1_ {
        background: $colorCaution;
    }
    &.obi__2_ {
        background: #7fccf0;
    }
    &.obi__3_ {
        background: #9bcb9b;
    }
    &.obi__4_ {
        background: #cc99cc;
    }
    &.obi__5_ {
        background: #99cdff;
    }
}

.obi__newOpen_ {
    background: $colorCaution;
}
.obi__renewal_ {
    background: #7fccf0;
}

.obi__taxFree_ {
    background: #ffd200;
}
.obi__srvTrans_ {
    background: #80c269;
}
.obi__srvSignLang_ {
    background: #8f82bc;
}

.obi__kidsSize {
    background: #57b3e8;
}
.obi__closed_ {
    background: #999999;
}
.obi__outlet_ {
    background: #fff;
    border: 1px solid $colorCaution;
    color: $colorCaution;
}

.obi__large {
    background: #80c269;
  }

.obi__online_ {
    background:#fd9827;
}

.obi__frame_ {
    background:#e94a92;
}

.obi__lens_ {
    background:#e20c0c;
}

.obi__security_ {
    background:#89c551;
}

.obi__shop_ {
    background:#179be1;
}

.obi__price_ {
    background:#fdc504;
}

.obi__sungrass_ {
    background:#844d9e;
}

.obi__another_ {
    background:#2261e1;
}

.obi__order_ {
    background:#fd8b04;
}

.obi__delivery_ {
    background:#3d9015;
}

.obi__line_ {
    background:#08bf5b;
}

/* ---------------------------------------------------------------
form
--------------------------------------------------------------- */
form {
    .formTitle_ {
        color: $zoffBlue;
        font-size: 1.6rem;
        margin-bottom: 10px;
        div {
            font-size: 1.4rem;
        }
    }
}

/* ---------------------------------------------------------------
check box
--------------------------------------------------------------- */
.termsCheck_input {
    display: none;
    + .termsCheck_label {
        &::before {
            content: "";
            height: 20px;
            width: 20px;
            margin-top: -10px;
            border: 2px solid #666;
            background: #fff;
            display: block;
            position: absolute;
            top: 50%;
            left: 20px;
            box-sizing: border-box;
        }
    }
    &:checked {
        + .termsCheck_label {
            &:after {
                content: "";
                height: 8px;
                width: 15px;
                margin-top: -10px;
                border-left: 4px solid $zoffBlue;
                border-bottom: 4px solid $zoffBlue;
                transform: rotate(-45deg);
                position: absolute;
                left: 22px;
                top: 50%;
            }
        }
    }
}

.termsCheck_label {
    width: 100%;
    padding: 20px 20px 20px 50px;
    background: $colorAreaGray;
    display: block;
    position: relative;
    box-sizing: border-box;
}

.cpformContainer {
    .cpformContainer_form_contents_questionnaireList_contents {
        input[type="checkbox"] {
            display: none;
            + label {
                font-size: 14px;
                width: 100%;
                padding: 10px 15px 10px 50px;
                border: 2px solid $colorLightGray;
                display: inline-block;
                position: relative;
                box-sizing: border-box;
                &::before {
                    content: "";
                    height: 24px;
                    width: 24px;
                    margin-top: -12px;
                    border: 5px solid #757575;
                    background: #fff;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    box-sizing: border-box;
                }
            }
            &:checked {
                + label {
                    &::after {
                        content: "";
                        height: 6px;
                        width: 13px;
                        margin-top: -8px;
                        border-left: 4px solid $zoffBlue;
                        border-bottom: 4px solid $zoffBlue;
                        transform: rotate(-45deg);
                        position: absolute;
                        left: 15px;
                        top: 50%;
                    }
                }
            }
        }
    }
}

/* ---------------------------------------------------------------
delete button
--------------------------------------------------------------- */
.cartItem__deleteBtn_ {
    margin-right: -15px;
    span {
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        display: block;
        position: relative;
        width: 44px;
        height: 44px;
        margin-right: 0;
        margin-left: auto;
        &::before {
            content: "";
            width: 25px;
            height: 2px;
            display: block;
            position: absolute;
            top: 21px;
            left: 8px;
            background:$zoffBlue;
            border-right:$zoffBlue solid 1px;
            transform: rotate(45deg);
            z-index: 1;
        }
        &::after {
            content: "";
            width: 25px;
            height: 2px;
            display: block;
            position: absolute;
            top: 21px;
            left: 8px;
            background:$zoffBlue;
            border-right:$zoffBlue solid 1px;
            transform: rotate(135deg);
            z-index: 1;
        }
    }
    label {
        overflow: hidden;
        text-indent: 100%;
        white-space: nowrap;
        display: block;
        position: relative;
        width: 44px;
        height: 44px;
        margin-right: 0;
        margin-left: auto;
        padding-right: 4%;
        color: #fff;
        &::before {
            content: "";
            width: 25px;
            height: 2px;
            display: block;
            position: absolute;
            top: 21px;
            left: 8px;
            background: $zoffBlue;
            border-right: $zoffBlue solid 1px;
            transform: rotate(45deg);
            z-index: 1;
        }
        &::after {
            content: "";
            width: 25px;
            height: 2px;
            display: block;
            position: absolute;
            top: 21px;
            left: 8px;
            background: $zoffBlue;
            border-right: $zoffBlue solid 1px;
            transform: rotate(135deg);
            z-index: 1;
        }
    }
    input {
        display: none;
    }
}

/* ---------------------------------------------------------------
radio button
--------------------------------------------------------------- */
.formLayout__lineFree_ {
    input[type="radio"] {
        display: none;
        + label {
            display: inline-block;
            padding-right: 10px;
            padding-left: 35px;
            position: relative;
            padding-bottom: 5px;
            padding-top: 5px;
        }
    }
    input {
        + label {
            &::before {
                content: "";
                display: block;
                position: absolute;
                top: 4px;
                left: 0;
                width: 16px;
                height: 16px;
                border: 5px solid $colorDelimiterGray;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                border-radius: 50%;
                background: #fff;
            }
        }
        &:checked {
            + label {
                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 11px;
                    left: 7px;
                    width: 12px;
                    height: 12px;
                    background: $zoffBlue;
                    border-radius: 50%;
                }
            }
        }
    }
}

.cpformContainer_form_contents {
    .formLayout__lineFree_ {
        label[for="purchase_1"] {
            margin-right: 20px;
        }
        label {
            padding-left: 32px;
            position: relative;
        }
        input {
            + label {
                &::before {
                    content: "";
                    height: 26px;
                    width: 26px;
                    margin-top: -13px;
                    border: 5px solid #757575;
                    border-radius: 50%;
                    background: #fff;
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    box-sizing: border-box;
                }
            }
            &:checked {
                + label {
                    &::after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 7px; 
                        display: block;
                        height: 12px;
                        width: 12px;
                        margin-top: -6px;
                        border-radius: 50%;
                        background: #fff;
                        background: $zoffBlue;
                    }
                }
            }
        }
    }
}

/* ---------------------------------------------------------------
badge
--------------------------------------------------------------- */
.category__icon_ {
    position: relative;
}

.badge_count {
    background: #f00;
    position: absolute;
    top: -.8em;
    left: 1.4em;
    font-size: 1rem;
    padding: 0.3em .5em;
    border-radius: 1em;
    line-height: 1;
    color: #fff;
}

/* ---------------------------------------------------------------
accordion
--------------------------------------------------------------- */
.accordion__area_ {
    border-top: 1px solid $colorDelimiterGray;
    img {
      vertical-align: bottom;
    }
    &:last-child {
      border-bottom: 1px solid $colorDelimiterGray;
    }
}
  
.accordion__inner_ {
    display: none;
    padding: 10px 0 20px;
}
  
.accordion__title_ {
    display: block;
    position: relative;
    margin-bottom: 0;
    padding: 15px 0 15px;
    &::before {
        content: "";
        display: block;
        position: absolute;
        border-bottom:$zoffBlue solid 1px;
        border-right:$zoffBlue solid 1px;
        width: 1px;
        height: 21px;
        top: 16px;
        right: 20px;
        border: none;
        transform: rotate(0deg);
        transition: .25s ease;
        background:$zoffBlue;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        width: 21px;
        top: 26px;
        right: 10px;
        transition: .25s ease;
        background:$zoffBlue;
    }
    &.on {
        &::before {
            display: none;
        }
    }
}

.accordion__titleLink {
    color: $zoffBlue;
}

.icon__plus_ {
    position: relative;
    &::before {
        content: "";
        display: block;
        position: absolute;
        border-bottom: $zoffBlue solid 1px;
        border-right: $zoffBlue solid 1px;
        width: 1px;
        height: 21px;
        top: 13px;
        right: 20px;
        border: none;
        transform: rotate(0deg);
        transition: .25s ease;
        background: $zoffBlue;
    }
    &::after {
        content: "";
        display: block;
        position: absolute;
        height: 1px;
        width: 21px;
        top: 23px;
        right: 10px;
        -webkit-transition: .25s ease;
        transition: .25s ease;
        background: $zoffBlue;
    }
}

/* ---------------------------------------------------------------
formdetail
--------------------------------------------------------------- */
.formdetail_ {
    dd {
        flex-wrap: wrap;
        display: flex;
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }
}

.formSelectWrap_ {
    position: relative;
    border: 1px solid $zoffBlue;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    &::before {
        content: "";
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        top: 17px;
        right: 1em;
        border: 1px solid;
        border-color: transparent $zoffBlue $zoffBlue transparent;
        transform: rotate(45deg);
        z-index: 3;
    }
    select {
        box-sizing: border-box;
        position: relative;
        z-index: 2;
        width: 100%;
        height: 50px;
        padding: 15px 30px 15px 7px;
        border:0;
        font-size: 1.6rem;
        line-height: 1.3;
        background: #fff;
        color: #000;
    }
}

.form__birth_ {
    label {
        display: inline-block;
        position: relative;
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 20px;
            right: .8em;
            width: 10px;
            height: 10px;
            border-bottom: $zoffBlue solid 1px;
            border-right: $zoffBlue solid 1px;
            transform: rotate(45deg);
            z-index: 5;
        }
        &:first-child {
            margin-left: 0;
        }
    }
    select {
        padding: 15px 30px 15px 7px;
        border: $zoffBlue solid 1px;
        font-size: 1.6rem;
        box-sizing: border-box;
        line-height: 1.3;
        position: relative;
        z-index: 2;
        height: 50px;
        max-width: 100%;
        border-radius:0;
        background: #fff;
        color: #000;
    }
}

.formLayout__lineMini_ {
    .formSelectWrap_ {
        vertical-align: middle;
        width: calc(4em + 20px);
        display: inline-block;
    }
}

form {
    label {
        display: block;
        font-size: 1.6rem;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.formRequired_ {
    color: #fff;
    display: inline-block;
    background: $zoffBlue;
    line-height: 1;
    padding: 4px 5px;
    font-weight: normal;
    vertical-align: text-top;
    margin-left: 10px;
    font-size: 1.4rem;
}

.familyinfoAddFormBtn_ {
    button {
        background: #fff;
        color: $zoffBlue;
    }
}

.formLayout__line2_ {
    display:flex;
    flex-wrap:wrap;
    flex-flow: row wrap;
    align-items:stretch;
    margin-bottom: 20px;
    .formLayout__line2__in_ {
        flex-basis: 48%;
    }
    .formLayout__line2__inLast_ {
        margin-left: 4%;
    }
    .container__inner_ {
        display: flex;
        flex-wrap: wrap;
    }
}

/* ---------------------------------------------------------------
pagenation
--------------------------------------------------------------- */
.pagenation_ {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 50px;
}

.navipage_sum_ {
  strong {
    color:$zoffBlue;
    font-size: 1.6rem;
    font-weight: normal;
  }
}

.navipage_ {
    nav {
        display:flex;
        justify-content:center;
        flex-wrap:wrap;
        padding-top:38px
    }
  a {
        color:#000;
        text-align:center;
        line-height:1;
        min-width:38px;
        padding:10px;
        display:inline-block;
        position:relative;
        box-sizing:border-box;
        border: 1px solid #d5d5d5;
        border-radius: 4px;
        margin: 0 3px;
  }
  .navipage_first_ {
    border:0;
    min-width: 19px;
    &::before{
        content:"";
        width:5px;
        height:5px;
        border:1px solid;
        border-color:transparent transparent #272727 #272727;
        transform:rotate(45deg);
        position:absolute;
        top:50%;
        left:50%;
        margin-top:-4px
    }
    &::after {
        content:"";
        width:5px;
        height:5px;
        border:1px solid;
        border-color:transparent transparent #272727 #272727;
        transform:rotate(45deg);
        position:absolute;
        top:50%;
        left:35%;
        margin-top:-4px
    }
  }
  .navipage_prev_ {
    padding-left:12px;
    padding-right:8px;
    border:0;
    min-width: 19px;
    &::before {
        content:"";
        width:5px;
        height:5px;
        border:1px solid;
        border-color:transparent transparent #272727 #272727;
        transform:rotate(45deg);
        position:absolute;
        top:50%;
        left:50%;
        margin-top:-4px
    }
  }
  .navipage_next_ {
    padding-left:8px;
    padding-right:12px;
    border:0;
    min-width: 19px;
    &::before {
        content:"";
        width:5px;
        height:5px;
        border:1px solid;
        border-color:transparent transparent #272727 #272727;
        transform:rotate(-135deg);
        position:absolute;
        top:50%;
        right:50%;
        margin-top:-4px
    }
  }
 .navipage_last_ {
     border:0;
     min-width: 19px;
    &::before {
        content:"";
        width:5px;
        height:5px;
        border:1px solid;
        border-color:transparent transparent #272727 #272727;
        transform:rotate(-135deg);
        position:absolute;
        top:50%;
        right:50%;
        margin-top:-4px
    }
    &::after {
        content:"";
        width:5px;
        height:5px;
        border:1px solid;
        border-color:transparent transparent #272727 #272727;
        transform:rotate(-135deg);
        position:absolute;
        top:50%;
        right:35%;
        margin-top:-4px
    }
  }
}

.navipage_now_ {
    color:#fff;
    background:$zoffBlue;
    font-weight:600;
    text-align:center;
    line-height:1;
    min-width:38px;
    padding:10px;
    border:1px solid #d5d5d5;
    border-radius:4px;
    display:inline-block;
    position:relative;
    box-sizing:border-box
}

/* ---------------------------------------------------------------
page__topBtn
--------------------------------------------------------------- */
// #page__topBtn {
//     display: block;
//     position: fixed;
//     bottom: 10px;
//     right: 10px;
//     z-index: 12;
//     width: 45px;
//     height: 45px;
//     border-radius: 100%;
//     background: #8bcdf0;
//     &::after {
//         display: block;
//         content: "";
//         position: absolute;
//         top: 18px;
//         left: 16px;
//         width: 14px;
//         height: 14px;
//         border-top: #fff solid 1px;
//         border-left: #fff solid 1px;
//         transform: rotate(45deg);
//     }
// }

#page__topBtn {
    &.page__topBtn {
      display: block;
      position: fixed;
      bottom: 20px;
      right: 20px;
      z-index: 12;
      width: 50px;
      height: 50px;
      background: transparent;
      @include transition(0s,linear,0s);
      &.js-absolute{
        position: absolute;
      }
  
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background: #000;
        border-radius: 100%;
        @include transition(.4s,ease,0s);
      }
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 25px;
        width: 8px;
        height: 8px;
        border-top: #fff solid 1px;
        border-left: #fff solid 1px;
        transform: translateY(-50%) rotate(45deg);
        transform-origin: left top;
      }
      &:hover{
        opacity: 1;
      }
    }
  }