//== [ 共通読み込み ]=============
//変数・mixin
@use 'variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

// [ footer ]
.footer {
    padding-top: 28px;
    padding-right: math.div(36,375)* 100vw;
    padding-left: calcVw(32);
    //
    &__social {
        display: flex;
        &Item {
            &:not(:last-child) {
                padding-right: 28px;
            }
        }
    }
    // [Navigation]
    &__nav {
        display: flex;
        padding-top: 40px;
        &Group {
            flex-grow: 1;
            font-size: 1.5rem;
            line-height: math.div(22,15);
            &:nth-of-type(2) {
                font-size: 1.3rem;
                line-height: math.div(22,13);
                .footer {
                    &__navListItem {
                        padding-bottom: 5px;
                    }
                }
                a {
                    &[target=_blank] {
                        &::after {
                            margin-left: 7px;

                        }
                    }
                }
            }
        }
        &ListItem {
            a {
                display: flex;
                align-items: center;
                &[target=_blank] {
                    &::after {
                        content: "";
                        display: block;
                        margin-left: 9px;
                        background: url(/img/private/sp/sitemap/blank.svg) center center no-repeat;
                    }
                }
            }
            &:not(:last-child) {
                padding-bottom: 20px;

            }
        }
    }
    // [ logo ]
    &__logo {
        padding-top: 63px;
    }
    // [Copyright]
    &__copyright {
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 1rem;
        line-height: math.div(22,10);
    }
}

//----------- Layout -----------
.l- {
    &footer {
        &__social {
            svg {
                width: auto;
                height: 20px;
            }
        }
        &__nav {
            &Group {
                &:first-of-type {
                    flex-basis: 112px;
                }
                &:nth-of-type(2) {
                    a {
                        &::after {
                            &[target=_blank] {
                                width: 10px;
                                height: 10px;
                                background-size: 10px auto;
                            }
                        }
                    }
                }
            }
            &ListItem {
                svg {
                    width: auto;
                    height: 20px;
                }
                a {
                    &[target=_blank] {
                        &::after {
                            width: 12px;
                            height: 12px;
                            background-size: 12px auto;
                        }
                    }
                }
            }
        }
        // [ logo ]
        &__logo {
            width: 120px;
        }
    }
}