//== [ 共通読み込み ]=============
//変数・mixin
@use 'variables' as *;
//===============================
//== [ モジュール読み込み ]========
// math（除算に[/]は使えない）
@use "sass:math";
//===============================

/* ---------------------------------------------------------------
Naviplus Header Search (旧 header_info.css)
--------------------------------------------------------------- */
.header {
  .header {
      &__navKeywordsSearchForm_ {
          box-sizing: border-box;
          display: none;
          position: fixed;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          padding-bottom: 20px;
          border-top: #fff solid 1px;
          background: #fff;
          z-index: 25;
          overflow: hidden;
          opacity: 0;
          overflow-y: auto;
          visibility: hidden;
          transition: 2.25s ease;
          &.js-open {
             display: block;
             opacity: 1;
             visibility: visible;
             animation: anim-searchFadein .5s 1 ease;
          }
      }
  
    // [ Navigation ]
      &__navKeywordsSearchFormInner_ {
        position: relative;
        width: auto;
        margin: 0 4%;
        border-bottom: $zoffBlue solid 2px;
        overflow: hidden;
      }
      &__navKeywordsSearchTitle_ {
        position: absolute;
        top: 30px;
        left: 0;
        width: 100%;
        letter-spacing: 0.1em;
    }
  }
}
    // [ Navigation ]
.infoSlider {
    background: #d9e4ea;
    position: relative;
    ul {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0 40px;
    }
    &__link {
      display: none;
      width: 100%;
      height: 60px;
      opacity: 0;
    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #000;
        font-size: 1.1rem;
        line-height: 1.25;
        text-align: center;
        text-decoration: none;
      }
      &--active {
          display: block;
          opacity: 1;
          animation-name: infoSliderFadein;
          animation-duration: 10000ms;
      }
    }
    &__prev {
      display: none;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 40px;
      height: 60px;
    }
    &__next {
      display: none;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 40px;
      height: 60px;
      &::before {
          content: "";
          display: block;
          height: 7px;
          width: 7px;
          border: 1px solid;
          border-color: transparent transparent $zoffBlue $zoffBlue;
          transform: rotate(45deg);
      }
      &::before {
          content: "";
          display: block;
          height: 7px;
          width: 7px;
          border: 1px solid;
          border-color: $zoffBlue $zoffBlue transparent transparent;
          transform: rotate(45deg);
        }
    }
}

@keyframes infoSliderFadein {
  0% {
      opacity: 0;
      transform: translateX(20px);
  }
  10% {
      opacity: 1;
      transform: translateX(0);
  }
}

/*- ============================================================
ヘッダーサーチ
============================================================ -*/
.header {
  .header {
    &__navKeywordsSearchForm_ {/* スクロール用 */
      display: none;
      height: 100%;
      width: 100%;
      border-top: #fff solid 1px;
      background: #fff;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 25;
      overflow: hidden;
      opacity: 0;
  
      /* 以降追加 */
      padding-bottom: 20px;
      box-sizing: border-box;
      overflow-y: auto;
      visibility: hidden;
      -webkit-transition: 2.25s ease;
      transition: 2.25s ease;
      &.js-open{/* OPEN用 */
        display: block;
        opacity: 1;
        visibility: visible;
        -webkit-animation: anim-searchFadein .5s 1 ease;
        animation: anim-searchFadein .5s 1 ease;
      }
      @-webkit-keyframes anim-searchFadein {
        0% { display: block; opacity: 0; }
        100% { display: block; opacity: 1; }
      }
      @keyframes anim-searchFadein {
        0% { display: block; opacity: 0; }
        100% { display: block; opacity: 1; }
      }
    }
    &__navKeywordsSearchText_ {
      display: inline-block;
      width: calc(88% - 1em);
      margin: 80px 0 0;
      padding: 0.5em;
      border: 0;
      border-radius: 0;
      font-size: 1.6rem;
      line-height: 1;
      color: $zoffBlue;
    }
    &__navKeywordsSearchBtn_ {
      display: inline-block;
      box-sizing: border-box;
      position: absolute;
      right: 2%;
      width: 10%;
      margin: 80px 0 0;
      padding: 0.5em;
      border: 0;
      font-size: 1.6rem;
      line-height: 1;
      color: $zoffBlue;
      background: #fff;
    }
    &__navKeywordsSearchClsBtn_ {
      width: 50px;
      height: 50px;
      display: block;
      position: absolute;
      top: 0;
      right: -4%;
      span {
        width: 26px;
        height: 2px;
        display: block;
        background: $zoffBlue;
        position: absolute;
        top: 50%;
        left: 12px;
        &:nth-child(1) {
          transform: rotate(45deg);
        }
        &:nth-child(2) {
          transform: rotate(-45deg);
        }
      }
    }
    &__navKeywordsSearchClsBtn_Bottom_ {/* 固定解除用 */
      display: flex;
      position: static;
      height: 40px;
      width: 35%;
      margin: 0 auto;
      color: $zoffBlue;
      border: solid 1px $zoffBlue;
      -webkit-transform: translateX(0);/* override用 */
      transform: translateX(0);/* override用 */
      &.header {
        &__navKeywordsSearchClsBtn_Bottom_ {
          span {
            left: 8px;
            &:nth-child(1) {
              transform: rotate(45deg);
            }
            &:nth-child(2) {
              transform: rotate(-45deg);
            }
          }
          p {
            position: absolute;
            left: 40px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.6rem;
          }
        }
      }
      span {
        width: 26px;
        height: 2px;
        display: block;
        background: $zoffBlue;
        position: absolute;
        top: 50%;
      }
    }
    &__navKeywordsLink {
      .findGkeywords{
        padding: 0 4%;
        margin-top: 20px;
        ul{
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }
  }
  .navKeywordsSearchClsBtn_ {
    display: block;
    position: absolute;
    top: 0;
    right: -4%;
    width: 50px;
    height: 50px;
  }
}
.navKeywordsLink {
  &__tableList{
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100vw;
    margin-top: calc(22vw/750*100);
    padding: calc(35vw/750*100) calc(83vw/750*100);
    border-top: calc(8vw/750*100) solid #f1f1f1;
    border-bottom: calc(8vw/750*100) solid #f1f1f1;
    .navKeywordsLink {
      &__contents{
        flex-basis: calc(280vw/750*100);
        max-width: calc(280vw/750*100);
      }
      &__link{
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(84vw/750*100);
        font-size: calc(28vw/750*100);
        text-align: center;
        background: $zoffBlue;
        color: #fff;
      }
      &__contents {
        .navKeywordsLink {
          &__link {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: calc(28vw/750*100);
            text-align: center;
            height: calc(84vw/750*100);
            background: $zoffBlue;
          }
        }
      }
    }
  }
  &__shapeList{
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100vw;
    padding: calc(35vw/750*100) calc(83vw/750*100);
    .navKeywordsLink {
      &__contents{
        flex-basis: calc(280vw/750*100);
        max-width: calc(280vw/750*100);
        margin-bottom: calc(30vw/750*100);
        .navKeywordsLink {
          &__link {
            box-sizing: border-box;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: calc(150vw/750*100);
            padding-bottom: calc(15vw/750*100);
            color: $zoffBlue;
            font-size: calc(26vw/750*100);
            font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
            font-weight: 600;
            text-align: center;
            border: calc(2vw/750*100) solid $zoffBlue;
          }
        }
        &:nth-child(1) {
          .navKeywordsLink {
            &__link {
              background: url(/img/private/sp/header/keyword_search/search_img01.jpg) no-repeat center center;
              background-size: contain;
            }
          }
        }
        &:nth-child(2) {
          .navKeywordsLink {
            &__link {
              background: url(/img/private/sp/header/keyword_search/search_img02.jpg) no-repeat center center;
              background-size: contain;
            }
          }
        }
        &:nth-child(3) {
          .navKeywordsLink {
            &__link {
              background: url(/img/private/sp/header/keyword_search/search_img03.jpg) no-repeat center center;
              background-size: contain;
            }
          }
        }
        &:nth-child(4) {
          .navKeywordsLink {
            &__link{
              background: url(/img/private/sp/header/keyword_search/search_img04.jpg) no-repeat center center;
              background-size: contain;
            }
          }
        }
        &:nth-child(5) {
          .navKeywordsLink {
            &__link{
              background: url(/img/private/sp/header/keyword_search/search_img05.jpg) no-repeat center center;
              background-size: contain;
            }
          }
        }
        &:nth-child(6) {
          .navKeywordsLink {
            &__link{
              background: url(/img/private/sp/header/keyword_search/search_img06.jpg) no-repeat center center;
              background-size: contain;
            }
          }
        }
      }
    }
  }
}

body {
  &.js-fixed {
    height: 100%;
    width: 100%;
    position: fixed;
    left: 0;
  }
}